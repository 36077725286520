import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import get from 'lodash/get'
import React from 'react'
import Helmet from 'react-helmet'
import FancyHeader from '../components/fancy-header'
import Footer from '../components/footer'
import Layout from '../components/layout'
import RichText from '../components/rich-text'
import styles from './about.module.css'

class About extends React.Component {
  render() {
    const about = get(this, 'props.data.allContentfulAbout.edges')[0].node
    return (
      <Layout location={this.props.location}>
        <div className={styles.pageLayout}>
          <Helmet title="About">
            <meta property="og:title" content={`Journey East | About`} />
            <meta property="og:type" content="website" />
            <meta
              property="og:description"
              content="Creative recipes from a Cantonese-American home cook in Tokyo."
            />
            <meta
              property="og:image"
              content="https://journeyeast.co/static/bc74c1ea2de3a99286bd1cb5f6928bd1/88d05/illo1.png"
            />
            <meta property="og:url" content={`https://journeyeast.co/about`} />
          </Helmet>
          <section className={styles.pageContent}>
            <section className={styles.intro}>
              <div className={styles.aboutHeader}>
                <RichText field={about.body} />
                <RichText field={about.aboutMe} />
              </div>
              <div>
                <Img width={260} fixed={about.profilePhoto.fixed} alt="" />
              </div>
            </section>
            <section className={styles.finalParagraph}>
              <FancyHeader title="Recipe Principles" />
              <RichText field={about.principles} />
            </section>
            <section className={styles.finalParagraph}>
              <FancyHeader title="Get in Touch" />
              <p>
                Have questions, feedback, or just want to chat? I'd love to hear
                from you. Hit me up via one of the following methods:
              </p>
              <p>
                Email:{' '}
                <a href="mailto:grace@journeyeast.co">grace@journeyeast.co</a>
                <br />
                Instagram:{' '}
                <a href="https://www.instagram.com/journeyeastzine/">
                  @journeyeastzine
                </a>
              </p>
            </section>
          </section>
        </div>
        <Footer />
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query AboutQuery {
    allContentfulAbout {
      edges {
        node {
          title
          body {
            json
          }
          aboutMe {
            json
          }
          challenge {
            json
          }
          principles {
            json
          }
          profilePhoto {
            fixed(width: 260) {
              ...GatsbyContentfulFixed_tracedSVG
            }
          }
        }
      }
    }
  }
`
