import React from 'react'
import styles from './fancy-header.module.css'

export default function FancyHeader({ title }) {
  return (
    <div className={styles.fancyHeader}>
      <h2 className={styles.fancyTitle}>{title}</h2>
      <div className={styles.fancyRule} />
    </div>
  )
}
